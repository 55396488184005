<template>
  <div class="EditTag">
    <div class="panel">
      <div class="Grid-row">
        <div class="Grid-column-12">
          <div class="field">
            <label for="name">Name</label>
            <input
              id="name"
              v-model="name"
              :class="{ 'has-error': !name && error }"
              :disabled="editMode"
              class="input"
              type="text"
            />
          </div>
          <div class="field">
            <label for="position">Position</label>
            <input
              id="position"
              v-model="position"
              :class="{ 'has-error': !position && error }"
              class="input"
              min="1"
              max="10000"
              type="number"
            />
          </div>
          <div class="field">
            <label for="post_count">Post count</label>
            <input
              id="post_count"
              v-model="postCount"
              :class="{ 'has-error': !postCount && error }"
              :disabled="editMode"
              class="input"
              type="text"
            />
          </div>
        </div>
        <div class="field">
          <label for="avatar">Avatar URL</label>
          <input
            id="avatar"
            ref="fileInput"
            class=""
            type="file"
            accept="image/*"
            @change="onFileChange"
          />
          <input
            v-model="imageUrl"
            :class="{ 'has-error': !imageUrl && error }"
            class="input"
          />
        </div>
        <div class="field">
          <label for="description">Description</label>
          <textarea
            id="description"
            v-model="description"
            :class="{ 'has-error': !description && error }"
            class="input input-textarea"
          />
        </div>
        <div class="field">
          <label for="subdescription">Subdescription</label>
          <input
            id="subdescription"
            v-model="subdescription"
            :class="{ 'has-error': !subdescription && error }"
            class="input"
            type="text"
          />
        </div>
      </div>
      <div class="Grid-row EditTag__buttons-row">
        <button
          class="button button-success"
          type="button"
          @click="editMode ? updateTag() : addTag()"
        >
          {{ editMode ? 'Save Tag' : 'Add Tag' }}
        </button>
        <button class="button button-danger" type="button" @click="cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { iconUpload } from '@/utils/aws.js';

export default {
  name: 'EditTag',

  data() {
    return {
      error: false,
      name: null,
      imageUrl: null,
      postCount: null,
      description: null,
      subdescription: null,
      coverUrl: null,
      position: null,
      custom: null,
    };
  },
  computed: {
    editMode() {
      return this.$route.params.tagIndex >= 0;
    },
    tag() {
      return this.$store.getters.tag({ tagIndex: this.$route.params.tagIndex });
    },
  },
  watch: {
    tag(newElement, oldElement) {
      if (!oldElement && newElement) {
        this.populateForm(newElement);
      }
    },
  },
  mounted() {
    if (this.tag) {
      this.populateForm(this.tag);
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'tags' });
    },
    updateTag() {
      if (!this.name) {
        this.error = true;
      } else {
        const data = {
          tagIndex: this.$route.params.tagIndex,
          tag: this.payload(),
        };
        this.$store.dispatch('updateTag', data);
        this.$router.push({ name: 'tags' });
      }
    },
    addTag() {
      if (!this.name) {
        this.error = true;
      } else {
        this.$store
          .dispatch('addTag', { tag: this.payload() })
          .then(this.$router.push({ name: 'tags' }));
      }
    },
    payload() {
      return {
        name: this.name,
        imageUrl: this.imageUrl,
        postCount: this.postCount,
        position: this.position,
        custom: this.custom || true,
        description: this.description || '',
        subdescription: this.subdescription || '',
        coverUrl: this.coverUrl || '',
      };
    },
    populateForm(tag) {
      this.name = tag.name;
      this.imageUrl = tag.imageUrl;
      this.postCount = tag.postCount;
      this.description = tag.description;
      this.subdescription = tag.subdescription;
      this.coverUrl = tag.coverUrl;
      this.position = tag.position;
      this.custom = tag.custom;
    },
    onFileChange(e) {
      this.$Progress.start();
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return false;
      const file = files[0];

      iconUpload({ file, progress: this.uploadProgress })
        .then((result) => {
          this.imageUrl = result.url;
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
    uploadProgress() {
      // console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/tags/EditTag.scss';
</style>
